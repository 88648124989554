<template>
  <div class="animated fadeIn">
    <b-form v-on:submit.prevent
            novalidate
            autocomplete="off">
      <b-card>
        <div slot="header">
          <h2>Reset your password</h2>
        </div>        

        <b-row>
            <b-col>
                <b-form-group>
                    <label for="newPassword" class="eden__label__required">New Password</label>
                    <password-toggle-input v-model="form.newPassword"                                           
                                           type="password"                                           
                                           placeholder="New Password"></password-toggle-input>
                    <label>Your password needs to be at least eight characters in length. Please include at least one lowercase, uppercase, number, and a special character(#?!@$%^&*-).</label>
                </b-form-group>
            </b-col>
        </b-row>        

        <b-row>
          <b-col>
            <b-form-group>
              <label for="newPassword2" class="eden__label__required">Re-enter Password</label>
              <password-toggle-input v-model="form.newPassword2"
                                     type="password"
                                     placeholder="Re-enter Password"></password-toggle-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col cols="12" xl="2" lg="3" md="6">
            <b-form-group>
              <b-button v-on:click="resetPassword"
                        ref="save"
                        id="save"
                        variant="primary"
                        class="w-100">
                <i class="fa fa-user mr-2"></i>
                Reset Password
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-form>
  </div>
</template>
<script>
  import { validationMixin } from 'vuelidate'
  import {
        required,
        minLength,
        sameAs,        
  } from 'vuelidate/lib/validators'
  import PasswordToggleInput from '@shared/components/PasswordToggleInput'

  export default {
    mixins: [
      validationMixin,
    ],

    components: {
      PasswordToggleInput,
    },

    data() {
        return {            
        form: {
          id: null,
          newPassword: null,
          newPassword2: null,
        },
      }
    },

    validations: {
      form: {
        newPassword: {
              required,              
              minLength: minLength(8),
              containsUppercase: function (newPassword) {                  
                  return /[A-Z]/.test(newPassword)
              },
              containsLowercase: function (newPassword) {
                  return /[a-z]/.test(newPassword)
              },
              containsNumber: function (newPassword) {
                  return /[0-9]/.test(newPassword)
              },
              containsSpecial: function (newPassword) {
                  return /[#?!@$%^&*-]/.test(newPassword)
              },
        },
            newPassword2: {
                required,
                sameAs: sameAs('newPassword'),
            },
      },
        },        

        methods: {                         

      async resetPassword() {
        // Form validation
        this.$v.form.$touch()

                if (!this.$v.form.newPassword.minLength) {
                    this.$toasted.global.error({
                        message: 'Your password needs at least 8 characters.'
                    })
                    return
                }
                else if (!this.$v.form.newPassword.containsUppercase) {
                    this.$toasted.global.error({
                        message: 'Your password requires at least one capitol letter.'
                    })
                    return
                }
                else if (!this.$v.form.newPassword.containsLowercase) {
                    this.$toasted.global.error({
                        message: 'Your password requires at least one lower case letter.'
                    })
                    return
                }
                else if (!this.$v.form.newPassword.containsNumber) {
                    this.$toasted.global.error({
                        message: 'Your password requires at least one number.'
                    })
                    return
                }
                else if (!this.$v.form.newPassword.containsSpecial) {
                    this.$toasted.global.error({
                        message: 'Your password requres a special character.'
                    })
                    return
                }
                else if (!this.$v.form.newPassword2.sameAs) {
                    this.$toasted.global.error({
                        message: 'Your password entires do not match.'
                    })
                    return
                }

        const loader = this.$loading.show()

        try {
          const { data } = await this.$request({
            url: '/api/auth/reset-password',
            method: 'post',
            data: this.form,
          })
          this.$toasted.global.success({
            message: 'Your password has been reset!',
          })
          this.$router.push('/login')
        } catch (e) {
          this.$log.error('Error resetting user password', e)
          this.$toasted.global.error({
            message: e.message,
          })
          return
        } finally {
          loader.hide()
        }
      },
    },

    async mounted() {
      const { id } = this.$route.query
      if (!id) {
        return
      }

      const loader = this.$loading.show()

      try {
        const { data } = await this.$request({
          url: `/api/auth/forgot-password/${id}`,
          method: 'get',
        })
        this.form.id = data.guid
      } catch (e) {
        this.$log.error('Error fetching forgot password record', e)
        this.$toasted.global.error({
          message: e.message,
        })
        return
      } finally {
        loader.hide()
      }
    },
  }
</script>
